import React, { useState, useEffect } from 'react';
import { Checkbox, Modal, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { MediaUrl } from "../../../services/File"
import { useNotification } from '../../../hooks/NotificationProvider';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { doS, capitalize,CheckIfUserIsBooked } from "../../../utils/miscHelpers"

import OperationPdf from './PdfOperation';

const CreatePdfModal = ({  storedOperation, staffList, isModalOpen, closeModal}) => {    
    
    const { t } = useTranslation();    
    const { openNotification } = useNotification();

    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [version, setVersion] = useState(1);
        
    const [cleanStaffList, setCleanStaffList] = useState(true);
    
    const divStyle = {
        borderRadius:'5px',
        marginRight:'6px',
        width:'10px',
        height:'10px',
    };

    useEffect(() => {
        const getStaffListMediaFiles = async (listStaff) =>{
            for (let staff of listStaff) {
                if (staff && staff.medias && staff.medias.length) {
                    const medias = staff.medias.filter((media)=>media.section === 'media');
                    if (medias && medias.length && (!staff.files || !staff.files.length)) {
                        staff.files = await MediaUrl(medias,'media','small') ;
                    }
                }
            }
            setCleanStaffList(listStaff)
        };        
        getStaffListMediaFiles(staffList);
    }, [staffList]);

    useEffect(() => {        
        setSelectedTypes([]);
        setSelectedUsers([]);
        setSelectedContacts([]);
    }, [isModalOpen]);    

    const onSelectType = (e,type) =>{
        let newSelection = [...selectedTypes];
        if (e.target.checked){
            if (!newSelection.includes(type)) {
                newSelection.push(type);
                if (type=== 'user') {
                    if (!selectedUsers.length) {
                        let newSelectionUsers = [...selectedUsers];
                        for(const staff of staffList){
                            newSelectionUsers.push(staff._id);
                        }
                        setSelectedUsers(newSelectionUsers);
                    }   
                } else if (type=== 'contact') {
                    if (!selectedContacts.length) {
                        let newSelectionContacts = [...selectedContacts];
                        for(const staff of staffList){
                            newSelectionContacts.push(staff._id);
                        }
                        setSelectedContacts(newSelectionContacts);
                    }   
                }           
            }
        } else {
            if (type=== 'user' && selectedUsers.length) {
                setSelectedUsers([]);
            } else if (type=== 'contact' && selectedContacts.length) {
                setSelectedContacts([]);
            }
            newSelection = newSelection.filter((ele)=> ele !== type);
        }
        setSelectedTypes(newSelection);
    };

    const onSelectUsers = (e,staffId) =>{
        let newSelection = [...selectedUsers];
        if (e.target.checked){
            if (!newSelection.includes(staffId)) newSelection.push(staffId);
        } else {            
            newSelection = newSelection.filter((ele)=> ele !== staffId);
        }
        setSelectedUsers(newSelection);

        if (newSelection.length) {
            let newSelectionType = [...selectedTypes];
            if (!newSelectionType.includes('user')){
                newSelectionType.push('user');
                setSelectedTypes(newSelectionType);
            }             
        } else {
            let newSelectionType = [...selectedTypes];
            newSelectionType = newSelectionType.filter((ele)=> ele !== 'user');
            setSelectedTypes(newSelectionType);
        }          
    };


    const onSelectContacts = (e,staffId) =>{
        let newSelection = [...selectedContacts];
        if (e.target.checked){
            if (!newSelection.includes(staffId)) newSelection.push(staffId);
        } else {            
            newSelection = newSelection.filter((ele)=> ele !== staffId);
        }
        setSelectedContacts(newSelection);

        if (newSelection.length) {
            let newSelectionType = [...selectedTypes];
            if (!newSelectionType.includes('contact')){
                newSelectionType.push('contact');
                setSelectedTypes(newSelectionType);
            }             
        } else {
            let newSelectionType = [...selectedTypes];
            newSelectionType = newSelectionType.filter((ele)=> ele !== 'contact');
            setSelectedTypes(newSelectionType);
        }          
    };

    const onGenPdf = async () =>{
        if (!selectedTypes.length) {
            return openNotification('error',"Aucun élément séléctionné");     
        }

        const blob = await pdf(
            <OperationPdf 
                storedOperation={storedOperation} 
                staffList={cleanStaffList} 
                selectedTypes={selectedTypes}
                selectedUsers={selectedUsers}
                selectedContacts={selectedContacts}
                version={version} 
            />
        ).toBlob();
        saveAs(blob, storedOperation.name+'_'+version+'.pdf');
        setVersion(version + 1);
    };
  
    return (
        <>
            <Modal 
                title="Création du PDF de Présentation de l'Opération"
                open={isModalOpen}
                onCancel={closeModal}
                footer={
                    <Space>
                        <div>
                            <Button className="button-small cancel spacer" type="primary" onClick={closeModal}>{t('buttons.cancel')}</Button>
                            <Button className="button-small apply"  type="primary" onClick={onGenPdf}>{t('buttons.generatePdf')}</Button>
                        </div>
                    </Space>
                }
            >
                <div >
                    <span className='bold'>{capitalize(storedOperation.name)}</span>                    
                </div>                     
                <div >
                    <span className=''>Nb. Staff : {staffList.length}</span>
                </div>
                <div className='flex' style={{paddingLeft:'32px',margin:'16px 0',flexDirection: "column"}}>
                    <div>
                        <Checkbox style={{marginRight:'10px'}} checked={selectedTypes.includes('cover')} className="custom-checkbox" onChange={(e)=>onSelectType(e,'cover')}>
                            <span>Cover</span>
                        </Checkbox>
                    </div>                      
                    <div>
                        <Checkbox style={{marginRight:'10px'}} checked={selectedTypes.includes('info')} className="custom-checkbox" onChange={(e)=>onSelectType(e,'info')}>
                            <span>Infos</span>
                        </Checkbox>
                    </div>                                               
                    <div>
                        <Checkbox style={{marginRight:'10px'}} checked={selectedTypes.includes('contact')} className="custom-checkbox" onChange={(e)=>onSelectType(e,'contact')}>
                            <span>Contacts</span>
                        </Checkbox>
                    </div>
                    <div style={{margin:'16px'}}  className="staff-grid">
                        {staffList && staffList.length>0 && staffList.map((staff,index)=>{
                            const isBooked = CheckIfUserIsBooked(staff._id,storedOperation);
                            return (
                                <Checkbox key={index.toString()} style={{marginRight:'10px'}} checked={selectedContacts.includes(staff._id)} className="custom-checkbox" onChange={(e)=>onSelectContacts(e,staff._id)}>
                                    <div className='flex flex-column'>
                                        <div style={{...divStyle, backgroundColor:isBooked ? 'var(--green-color)' : 'white'}} />
                                        <span >{capitalize(staff.firstName)} {capitalize(staff.lastName)}</span>
                                    </div>                                    
                                </Checkbox>   
                            )
                        })}                     
                    </div>
                    <div>
                        <Checkbox style={{marginRight:'10px'}} checked={selectedTypes.includes('quote')} className="custom-checkbox" onChange={(e)=>onSelectType(e,'quote')}>
                            <span>Devis</span>
                        </Checkbox>
                    </div>
                    <div>
                        <Checkbox style={{marginRight:'10px'}} checked={selectedTypes.includes('mission')} className="custom-checkbox" onChange={(e)=>onSelectType(e,'mission')}>
                            <span>Missions</span>
                        </Checkbox>
                    </div>                      
                    <div>
                        <Checkbox style={{marginRight:'10px'}} checked={selectedTypes.includes('user')} className="custom-checkbox" onChange={(e)=>onSelectType(e,'user')}>
                            <span>Hôtesses</span>
                        </Checkbox>                        
                    </div>
                    <div style={{margin:'16px'}}  className="staff-grid">
                        {staffList && staffList.length>0 && staffList.map((staff,index)=>{
                            const selectedMedias = staff.medias.filter((media)=> media.section === 'media' && media.selected === true);
                            const totalMedias    = staff.medias.filter((media)=> media.section === 'media');
                            const isBooked = CheckIfUserIsBooked(staff._id,storedOperation);
                            return (
                                <Checkbox key={index.toString()} style={{marginRight:'10px'}} checked={selectedUsers.includes(staff._id)} className="custom-checkbox" onChange={(e)=>onSelectUsers(e,staff._id)}>
                                    <div className='flex flex-column'>
                                        <div style={{...divStyle, backgroundColor:isBooked ? 'var(--green-color)' : 'white'}} />
                                        <span title={selectedMedias.length+' photo'+doS(selectedMedias)+' séléctionnée'+doS(selectedMedias)+' sur '+ totalMedias.length}>{capitalize(staff.firstName)} {capitalize(staff.lastName)} ({selectedMedias.length}/{totalMedias.length})</span>
                                    </div>                                    
                                </Checkbox>   
                            )
                        })}                     
                    </div>                        
                </div>                                  
            </Modal>
        </>
    );
};

// Font.register({
//     family: 'Poppins',
//     fonts: [
//       { src: PoppinsRegular },
//       { src: PoppinsBold, fontWeight: 'bold' },
//       { src: PoppinsItalic, fontStyle: 'italic' },
//     ], 
// });

// const styles = StyleSheet.create({
//     cover: {
//         width: '100%',
//         height: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         position: 'relative',
//         backgroundColor:'#433F3A'
        
//     },
//     page: {
//         flexDirection: 'column',
//         backgroundColor: '#ffffff',
//         fontFamily: 'Poppins', 
//         fontSize: 12,    
//         padding:16,
//     },
//     section: {
//         margin: 16,
//         // padding: 10,
//         //   flexGrow: 1
//     },
//       pageUnWrappable: {
//         flexDirection: 'column',
//         backgroundColor: '#ffffff',
//         fontFamily: 'Poppins', 
//         fontSize: 12,
//       },      
//      sectionUnWrappable: {
//         margin: 16,
//         padding: 16,
//         //   flexGrow: 1
//     },      

//     logoContainer: {
//         flex: 1,
//         justifyContent: 'center',
//         alignItems: 'center',
//     },
//     svg: {
//         width: '50%', // Adjust as needed
//         height: '50%', // Adjust as needed
//     },  
//     logo: {
//         width: 300,
//         height: 190,
//         color:'#ff0000'
//     },
//     staffImage : {
//         width: 300,
//         height: 360,
//         objectFit:"cover"
//     },
//     sectionImage : {
//         // flexGrow: 1
//         display:'flex',
//         flexDirection:'row',
//         justifyContent:'flex-start',        
//     },
//     smallSection:{
//         fontSize:10,
//     },
//     inline : {
//         display:'flex',
//         flexDirection:'row',
//         justifyContent:'flex-start',
//         marginTop:8,
//         marginBottom:8,
//     },
//     row : {
//         display:'flex',
//         flexDirection:'row',
//         justifyContent:'flex-start',
//     },      
//     rowWithColumns : {
//         display:'flex',
//         flexDirection:'row',
//         justifyContent:'space-between',
//     },   
//     semibold : {
//         fontWeight:400,
//     },
//     left: {
//         textAlign:'left',
//     },
//     column : {
//         width:'16%',  
//         textAlign:'right',      
//         border:'solid 1px black'
//     },
//     uppercase : {
//         textTransform:"uppercase",
//     },
//     h2: {
//         fontSize: 28,        
//         color:'#C9B39B',
//         fontWeight:600,
//     },
//     h3: {
//         fontSize: 18,        
//     },  
//     h4: {
//         fontSize: 14,   
//         fontWeight:600,
//     },      
//     accent : {
//         color:'#C9B39B',
//     },
//     bold : {
//         fontWeight:600,
//     },
//     cartouche : {
//         border: '0.5px solid #C9B39B',
//         borderRadius:6,
//         padding:6,
//         backgroundColor:'#C9B39B',
//     },
//     body: {
//         fontSize: 12,    
//     }, 
//     bodySmall: {
//         fontSize: 11,  
//         // fontStyle:'italic',
//     },      
//     block:{
//         marginTop:32,
//         marginBottom:64,
//     },
//     blockNeutral:{
//         marginTop:16,
//         marginBottom:16,
//     }, 
//     hr : { 
//         width: '100%', 
//         border: '0.3px solid #C9B39B',
//         marginTop:6,
//         marginBottom:6
//     },
//     big:{
//         fontSize:33,
//         display:'inline',
//     },
//     smallFont : {
//         fontSize:9,
//     },
//     smallerFont : {
//         fontSize:7,
//     },    
//     footer: {
//         position: 'absolute',
//         bottom: '16px', // 20px from the bottom
//         left: '0',
//         right: '0',
//         textAlign: 'center',
//         fontSize:8,
//         color: '#808080',
//     },      
// });
  

export default CreatePdfModal;
