import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Checkbox, Modal, Space, Button } from 'antd';
import { IssuesCloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { capitalize, CheckStaffAgenda, GetMissionStaffStatus , FormatMissionSlotsByRoles, getAge, scoreContent } from "../../../utils/miscHelpers"
import { SlotBmgStatus  } from "../../../utils/constants"
import LegendStatus from '../../../components/elements/LegendStatus'

const StaffMissionSlotsModal = ({staffList,  storedOperation, isModalOpen, closeModal, onAffectUsers}) => {    
    
    const { t } = useTranslation();    

    const roles = FormatMissionSlotsByRoles(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : [])    
    
    const [selectedSlots, setSelectedSlots] = useState([]);
    //const [bookedStaff, setBookedStaff] = useState({});
    const [selectedRoleKeySlots, setSelectedRoleKeySlots] = useState([]);
    const [roleKeyDisabled, setRoleKeyDisabled] = useState([]);

    useEffect(() => {
        
        const selectStaff = () =>{
            // Parse Staff Missions Already Selected
            const staffSelected = [];    
            for (const roleKey in roles){
                if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length) {            
                    let idx = 0;
                    for(const mission of roles[roleKey].missionSlots){
                        if (mission && mission.users && mission.users.length) {
                            let staffIdx = 0;
                            for(const staff of staffList){
                                if (mission && 
                                    mission.users && 
                                    mission.users.includes(staff._id) 
                                ) staffSelected.push(roleKey+'-'+idx+'-'+staffIdx);
                                staffIdx++;
                            }
                        }
                        idx++;
                    }
                }        
            }
            setSelectedSlots(staffSelected);
        };

        const roleKeyDisable = () =>{
            const onRoleKeyDisabled = (roleKey) => {
                setRoleKeyDisabled((prevRoleKeyDisabled) => {
                    let newRoleKeyDisabled = [...prevRoleKeyDisabled];
                    if (!newRoleKeyDisabled.includes(roleKey)) newRoleKeyDisabled.push(roleKey);
                    return newRoleKeyDisabled;
                });
            };            
            if (roles) {
                Object.keys(roles).map((roleKey,index)=>{
                    const role = roles[roleKey];
                    if (role.missionSlots && role.missionSlots.length>0) {
                        role.missionSlots.map((mission,idx)=>{
                            return staffList.map((staff,staffIdx)=>{
                                const missionStaffStatus = GetMissionStaffStatus(storedOperation._id, staff, mission);                                 
                                if (missionStaffStatus !== null) onRoleKeyDisabled(roleKey+'-'+staffIdx);
                                return true;
                            });        
                        });
                    }
                    return true;
                });
            }
        };

        const staffBookedMissions = () =>{
            let staffBooked = {};
            if (staffList && staffList.length) {
                for(const staff of staffList){
                    if (staff  && staff.missions && staff.missions.length) {
                        for (const staffMission of staff.missions) {                                                        
                            if (staffMission && 
                                staffMission._operation && 
                                staffMission._operation._id && 
                                staffMission._operation._id !== storedOperation._id && 
                                staffMission.slots && 
                                staffMission.slots.length) {
                                for (const staffSlot of staffMission.slots) {
                                    // console.log('staffSlot',staffSlot);
                                    if(!staffBooked[staff._id]) staffBooked[staff._id] = [];
                                    staffBooked[staff._id].push({
                                        name:staffMission._operation.name,
                                        eday:staffSlot.eday,
                                        stime:staffSlot.stime,
                                        etime:staffSlot.etime,
                                    });
                                }                                
                            }
                        }
                    }
                }
            }            
            //setBookedStaff(staffBooked);
        };

        staffBookedMissions();
        selectStaff();
        roleKeyDisable();

    }, [isModalOpen]);
    
    const onCancel = ()=>{      
        setSelectedRoleKeySlots([]);  
        setSelectedSlots([]);
        closeModal();
    };

    const onSubmit = async ()=>{        
        onAffectUsers(staffList,[...selectedSlots]);
        setSelectedRoleKeySlots([]);  
        setSelectedSlots([]);
        closeModal();
    };

    const onCheckbox = (cellKey)=>{
        let newSelectedBox = [...selectedSlots];
        if (newSelectedBox.includes(cellKey)) {
            newSelectedBox = newSelectedBox.filter((ele)=>ele !== cellKey)
        } else {
            newSelectedBox.push(cellKey);            
        }
        setSelectedSlots(newSelectedBox);        
    }
    const linkMeToUser = async (user)=>{
        return window.open(process.env.REACT_APP_PUBLIC_BO_URL+'/user/detail?staffId='+user._id);
    };
    const onRoleCheckbox = (cellKey)=>{
        const [roleKey,staffIdx] = cellKey.split('-');
        let newSelectedRoleKeySlots = [...selectedRoleKeySlots];
        let newSelectedBox = [...selectedSlots];
        if (newSelectedRoleKeySlots.includes(cellKey)) {
            newSelectedRoleKeySlots = newSelectedRoleKeySlots.filter((ele)=>ele !== cellKey);

            if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length){                
                for(const idx in roles[roleKey].missionSlots){
                    const kkey = roleKey+'-'+idx+'-'+staffIdx;
                    newSelectedBox = newSelectedBox.filter((ele)=>ele !== kkey)
                }
            }  
        } else {
            newSelectedRoleKeySlots.push(cellKey);

            if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length){                
                for(const idx in roles[roleKey].missionSlots){
                    const kkey = roleKey+'-'+idx+'-'+staffIdx;
                    if (!newSelectedBox.includes(kkey)) newSelectedBox.push(kkey);
                }
            }            
        }
        setSelectedRoleKeySlots(newSelectedRoleKeySlots);        
        setSelectedSlots(newSelectedBox);
    }
    
    const onCheckAll = () =>{        
        let newSelectedBox = [];
        if (roles && Object.keys(roles).length > 0){
            for(const roleKey in roles){                
                if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length){                
                    for(const idx in roles[roleKey].missionSlots){
                        //let staffIdx=0;
                        for(const staffIdx in staffList){
                            const kkey = roleKey+'-'+idx+'-'+staffIdx;
                            if (!newSelectedBox.includes(kkey)) newSelectedBox.push(kkey);
                            //staffIdx++;
                        }
                    }
                }  
            }
        }
        setSelectedSlots(newSelectedBox);
    };
    
    return (
        <>
            <Modal 
                title="Géstion du Staff & des Roles"
                open={isModalOpen}
                onCancel={onCancel}
                footer={
                    <Space>
                    {staffList && staffList.length !== 0 &&
                        <div className='flex' style={{alignItems:'center'}}>
                            <div className='link' onClick={onCheckAll}>Tout séléctionner</div>
                            <Button className="button-small cancel spacer" type="primary" onClick={onCancel}>{t('buttons.cancel')}</Button>
                            <Button className="button-small apply" type="primary" onClick={onSubmit}>{t('buttons.affectStaffOp')}</Button>
                        </div>
                    }
                    </Space>
                }
                width={'90%'}
                >
                <div >
                    {(roles && Object.keys(roles).length > 0) &&
                        <div style={{overflowY:'auto',height:"600px"}}>                            
                            
                            <div className='mission-table-role-row' style={{zIndex:1,marginTop:'0px',position:'absolute'}}> 
                                <div >                                                
                                    <div  className='flex' style={{width:'100%',alignItems: "center",justifyContent: "flex-start"}}>
                                        <div className='flex cartouche-mission-item_blank  mission-table-role-cell' style={{  alignSelf: "self-start" ,height:'130px',backgroundColor:'white',justifyContent: "flex-start",}}>
                                            <LegendStatus shape='square' type='affect' direction='column'/>
                                        </div>
                                        <div className='mission-table-staff-row'>
                                            {staffList.map((staff,idx)=>{                                                                 
                                                    return(
                                                        <div key={idx.toString()} className=' is-header mission-table-staff-cell' style={{width: staffList.length ? 96 / staffList.length+'%' : 0}}> 
                                                            <div>
                                                                <div className="" style={{alignItems: "center",padding:'0 0',width:'100%',margin:'0 0'}}>
                                                                    <div className='flex' style={{alignItems: "center",flexDirection:'column'}}>
                                                                        <div className='link' onClick={()=>linkMeToUser(staff)} >
                                                                            <img alt="user" src={staff.avatar ? staff.avatar : '/img/placeholder-avatar.jpg'} className="avatar-small" />
                                                                        </div>
                                                                        {(staff.score !== null && staff.score !== 0) && 
                                                                          <div className="score-list-user-small">{scoreContent(staff.score,'score-small')}</div>
                                                                        }                                                                     
                                                                        <div className="custom-name capitalize bold link"  onClick={()=>linkMeToUser(staff)} >{staff.firstName +' '+staff.lastName}</div> 
                                                                        <div>
                                                                            <span className='tab-table-row-span'>({staff.gender === 1 ? 'H' : 'F'})</span>
                                                                            <span className='tab-table-row-span'>{staff.birthday ? getAge(staff.birthday)+' ans' : ''}</span>
                                                                            <span className='tab-table-row-span'>{staff.height ? (staff.height/100).toFixed(2)+'m' : ''}</span>
                                                                            <span className='tab-table-row-span'>{capitalize(staff.city)}</span>
                                                                            
                                                                        </div>
                                                                        {/* {MissionBmgStatus[staff.operationStatus] && 
                                                                            <span className="capitalize tag" style={{backgroundColor:MissionBmgStatus[staff.operationStatus].color}}>{MissionBmgStatus[staff.operationStatus].label}</span>
                                                                        }                                                                         */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>                                                                                                                                      
                                    </div>                                                
                                </div>
                            </div>
                                                                        
                            {Object.keys(roles).map((roleKey,index)=>{
                                const role = roles[roleKey];                                
                                if (role) {
                                    return(
                                        <div key={index.toString()} className='mission-table-role-row' style={{marginTop: index === 0 ? '130px' : 0}}>
                                            <div  className='flex' style={{
                                                    height:'40px',
                                                    backgroundColor:'#000',
                                                    width:'100%',
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                }}> 
                                                <div className='cartouche-mission-item_blank mission-table-role-cell' style={{backgroundColor:'#000',fontSize:'12px'}}>
                                                    <div>
                                                        <span className='uppercase bold' style={{color:'white'}}>{role.name}</span>
                                                        <span style={{margin:'0 6px'}}>-</span>
                                                        <span className='uppercase bold' style={{color:'var(--accent-color)'}}>{role.price} / {role.cost} {role.currency}</span>
                                                    </div>
                                                </div>
                                                <div className='mission-table-staff-row'>
                                                    {staffList.map((staff,staffIdx)=>{            
                                                            const cellKey = roleKey+'-'+staffIdx;                                    
                                                            return(
                                                                <div key={staffIdx.toString()} className='mission-table-staff-cell' >
                                                                    <div>
                                                                        {staff.operationStatus !== 0 && !roleKeyDisabled.includes(cellKey)  &&
                                                                            <Checkbox style={{zIndex:1}} className='custom-checkbox' checked={selectedRoleKeySlots.includes(cellKey)} onChange={()=> onRoleCheckbox(cellKey)}/>
                                                                        }                                                                        
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>  
                                            </div>
                                            <div style={{padding:'16px 0',margin:'0 0'}}>
                                                {role.missionSlots && role.missionSlots.length>0 && role.missionSlots.map((mission,idx)=>
                                                    <div key={idx.toString()}  className='flex' style={{width:'100%',alignItems: "center",justifyContent: "flex-start",}}>
                                                        <div className='cartouche-mission-item mission-table-role-cell' style={{fontSize:'12px'}}>
                                                            <div>
                                                                <div className='bold'>{capitalize(mission.location.name)}  {capitalize(mission.location.address)} {capitalize(mission.location.city)}  - {mission.users ? mission.users.length : 0} / {mission.workForce}</div>
                                                                <div className=' bold capitalize' >{dayjs(mission.eday).format('dddd D MMMM YYYY')}</div>
                                                                <div className='uppercase' >{role.name} - {mission.timeSlot.stime} <span className='uncapitalize'>à</span> {mission.timeSlot.etime}</div>                                                                
                                                            </div>                                                                                                                      
                                                        </div>
                                                        <div className='mission-table-staff-row'>
                                                            {staffList.map((staff,staffIdx)=>{                                                                      
                                                                const cellKey = roleKey+'-'+idx+'-'+staffIdx;
                                                                const missionStaffStatus = GetMissionStaffStatus(storedOperation._id, staff, mission);
                                                                const staffAgenda = CheckStaffAgenda(storedOperation,[{eday:mission.eday,stime:mission.timeSlot.stime,etime:mission.timeSlot.etime}],staff.missions);
                                                                const slotAgenda = staffAgenda.find((ele)=> ele.eday === mission.eday && ele.stime === mission.timeSlot.stime && ele.etime === mission.timeSlot.etime && !ele.bookable);
                                                                return(
                                                                    <div key={staffIdx.toString()} className='mission-table-staff-cell' >
                                                                        {slotAgenda && slotAgenda.bookable === false && 
                                                                            <div><IssuesCloseOutlined color='black' style={{color:'var(--violet-color)',fontSize:'21px',cursor:'cell'}} title={'Déja Booké(e) sur '+(slotAgenda && slotAgenda.operation ? slotAgenda.operation : '')}/></div>
                                                                        }
                                                                        {(!slotAgenda || (slotAgenda && slotAgenda.bookable)) && 
                                                                            <div>
                                                                                <Checkbox style={{zIndex:1}}  className={'custom-checkbox big ' + (SlotBmgStatus[missionStaffStatus] ? 'has-'+SlotBmgStatus[missionStaffStatus].css : '') } checked={selectedSlots.includes(cellKey)} disabled={missionStaffStatus ? true : false} onChange={()=> !missionStaffStatus ? onCheckbox(cellKey) : null}/>
                                                                                {/* {missionStaffStatus !== 0 && 
                                                                                    <Checkbox style={{zIndex:1}}  className={'custom-checkbox big ' + (SlotBmgStatus[missionStaffStatus] ? 'has-'+SlotBmgStatus[missionStaffStatus].css : '') } checked={selectedSlots.includes(cellKey)} disabled={missionStaffStatus ? true : false} onChange={()=> !missionStaffStatus ? onCheckbox(cellKey) : null}/>
                                                                                }
                                                                                {missionStaffStatus === 0 && 
                                                                                    <Checkbox style={{zIndex:1}} className='custom-checkbox big has-red-color red-color radius-it' disabled={true}/>                                                                                    
                                                                                } */}
                                                                            </div>                                                                        
                                                                        }

                                                                    </div>
                                                                )
                                                                })
                                                            }
                                                        </div>                                                                                                                                                                   
                                                    </div>                                                
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                                return false;
                            })}
                        </div>
                    }                                   
                </div>                     
            </Modal>
        </>
    );
};
export default StaffMissionSlotsModal;
