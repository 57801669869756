import React, { useState, useEffect } from 'react';
import { Spin, Input,Button, Tag, Drawer, Checkbox,  Select, Space } from 'antd';
import { MenuOutlined, LeftCircleOutlined, FilterOutlined, NumberOutlined, DashboardOutlined,MailOutlined,TeamOutlined, BarsOutlined, SettingOutlined, FieldTimeOutlined, UserOutlined, ApartmentOutlined, CarryOutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { saveFilters } from '../../features/filters/filtersSlice';
//import { useAuth } from "../../hooks/AuthProvider";
import MenuItems from './MenuItems';

import {geographicAvailibilities,BmgStatusArr, Languages, CountriesFr, ScoresValues, AreaExpertises, BodySizes, Genders} from "../../utils/constants"
import {  importBmgFilters, changeArrayToLabelValue,convertListToValueLabel, formatListWithValueLabel, formatUserListWithValueLabel } from "../../utils/miscHelpers"

const { Search } = Input;

const SectionIcon = ({section,className})=> {
  switch(section) {
    case 'dashboard':
      return <DashboardOutlined className={className} />;            
    case 'agenda':
      return <FieldTimeOutlined className={className} />;      
    case 'todo':
      return <CarryOutOutlined className={className} />;      
    case 'user':
      return <UserOutlined className={className} />;      
    case 'client':
      return <ApartmentOutlined className={className} />;      
    case 'operation':
      return <TeamOutlined className={className} />;      
    case 'joboffer':
      return <BarsOutlined className={className} />;      
    case 'message':
      return <MailOutlined className={className} />;      
    case 'setting':
      return <SettingOutlined className={className} />;   
    case 'label':
        return <NumberOutlined className={className} />;                  
    default:
      return <FieldTimeOutlined className={className} />;
  }
}

const HeaderBar = ({loading,title, actionContent, todoContent, actionMethod,hasBack, hasFilters, hasSearch, hasSort, sorterItem=null, section, subSection=null, storedFilters, setCurrentPage,clientList=[]}) => {

  const sortByArr = [
    {label:'Alphabétique',value:'lastName'},
    {label:'Chronologique',value:'cdate'},
    {label:'Etoiles',value:'score'},
    {label:'Age',value:'birthday'},
  ];
  const messageListType = [
    {label:'Par Hôtesses',value:'_user'},
    {label:'Par Messages',value:'default'},
  ];
  const orderByArr = [
    {label:'Asc.',value:1},
    {label:'Desc.',value:-1},
  ];
  
  const defaultListType = section === 'message' ? '_user' : 'default';

  const storedUserList = useSelector(state => state.constants.value && state.constants.value && state.constants.value.user ? state.constants.value.user : []);
  const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);  

  const navigate = useNavigate();  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const [hasNavigateBack, setHasNavigateBack] = useState(false);

  const [open, setOpen] = useState(false);   
  
  const [BmgFilters, setBmgFilters] = useState(null); 
  
  const [searchTerm, setSearchTerm] = useState(storedFilters && storedFilters.term ? storedFilters.term : '');
  const [listType, setListType] = useState(storedFilters && storedFilters.listType ? storedFilters.listType : defaultListType);
  const [sortBy, setSortBy] = useState(sorterItem ? sorterItem.sortBy : 'cdate');
  const [orderBy, setOrderBy] = useState(sorterItem ? sorterItem.orderBy : -1);

  const [admins, setAdmins] = useState(storedFilters && storedFilters.admins ? storedFilters.admins : []);
  const [clientId, setClientId] = useState(storedFilters && storedFilters._client ? storedFilters._client : null);
  const [languages, setLanguages] = useState(storedFilters && storedFilters.languages ? storedFilters.languages : []);
  const [types, setTypes] = useState(storedFilters && storedFilters.types ? storedFilters.types : []);
  const [users, setUsers] = useState(storedFilters && storedFilters.users ? storedFilters.users : []);
  const [country, setCountry] = useState(storedFilters && storedFilters.country ? storedFilters.country : []);
  const [city, setCity] = useState(storedFilters && storedFilters.city ? storedFilters.city : []);
  const [activity, setActivity] = useState(storedFilters && storedFilters.activity ? storedFilters.activity : []);
  const [gender, setGender] = useState(storedFilters && storedFilters.gender ? storedFilters.gender : [1,2]);
  const [bmgStatus, setBmgStatus] = useState(storedFilters && storedFilters.bmgStatus ? storedFilters.bmgStatus : []);
  const [areaExpertise, setAreaExpertise] = useState(storedFilters && storedFilters.areaExpertise ? storedFilters.areaExpertise : []);
  const [nationality, setNationality] = useState(storedFilters && storedFilters.nationality ? storedFilters.nationality : []);
  const [bodyUp, setBodyUp] = useState(storedFilters && storedFilters.bodyUp ? storedFilters.bodyUp : []);
  const [bodyDown, setBodyDown] = useState(storedFilters && storedFilters.bodyDown ? storedFilters.bodyDown : []);  
  const [minHeight, setMinHeight] = useState(storedFilters && storedFilters.height && storedFilters.height.min ? storedFilters.height.min : 100);
  const [maxHeight, setMaxHeight] = useState(storedFilters && storedFilters.height && storedFilters.height.max ? storedFilters.height.max : 200);
  const [minAge, setMinAge] = useState(storedFilters && storedFilters.age && storedFilters.age.min ? storedFilters.age.min : 10);
  const [maxAge, setMaxAge] = useState(storedFilters && storedFilters.max && storedFilters.age.max ? storedFilters.age.max : 100);
  const [scores, setScores] = useState(storedFilters && storedFilters.scores ? storedFilters.scores : null);  
  const [labels, setLabels] = useState(storedFilters && storedFilters.labels ? storedFilters.labels : null);  
  const [geographicAvailability, setGeographicAvailabitity] = useState(storedFilters && storedFilters.geographicAvailability ? storedFilters.geographicAvailability : []);
  
  const [mobileMenuToogle, setMobileMenuToogle] = useState(false);
  const toogleMobileMenu = ()=>{
    setMobileMenuToogle(!mobileMenuToogle);
  };

  useEffect(() => {
    const updateHasBack = () => {
      setHasNavigateBack(window.history.length > 1);
    };

    updateHasBack(); // Check initially when the component mounts

    // Add an event listener for popstate (browser navigation)
    window.addEventListener('popstate', updateHasBack);

    return () => {
      window.removeEventListener('popstate', updateHasBack);
    };
  }, []);

  useEffect(() => {
    const fetchBmgFilters = async () => {
      if (section!== 'message') {
        const bmgFiltersList = await importBmgFilters();
        setBmgFilters(bmgFiltersList);
      }

      const sortFilter = prepareSort(sortBy,orderBy);      
      const listTypeFilter = {listType:listType};      
      dispatch(saveFilters({section:section,data:{...storedFilters,...sortFilter,...listTypeFilter}}));    

    };
    fetchBmgFilters(); 
  }, [sortBy,orderBy]);

  const onChangeSearchTerm = (e) => {        
    setSearchTerm(e.target.value);    
  };

  const onSearchTerm = (value) => {    
    setCurrentPage(1);
    dispatch(saveFilters({section:section,data:{...storedFilters,...{term:value}}}));
  };

  const prepareSort = (sortProp,sortOrder) => {
    if (['operation','client'].includes(section)) return {};

    let sortFilter = {};
    sortFilter[sortProp] = sortOrder;
    if (sortBy === 'birthday') { sortFilter[sortProp] = -sortOrder; }
    return {sort : sortFilter};
  }

  const onChangeOrderBy = (value) => {
    setOrderBy(value);
  };

  const onChangeSortBy = (value) => {      
    setSortBy(value);
  };

  const onChangeListType = (value) => {
    setListType(value);
    actionMethod();
    let usersFilter = {};
    if (users.length) usersFilter = {users: users};
    const newFilters = {listType:value, ...usersFilter};
    dispatch(saveFilters({section:section,data:newFilters}));
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const onFilterApply = (e) => {    

    let languagesFilter = {};    
    let typesFilter = {};   
    let usersFilter = {};
    let countryFilter = {};    
    let cityFilter = {};    
    let geoAvailablitiesFilter = {};    
    let activityFilter = {};         
    let genderFilter = {};
    let bmgStatusFilter = {};    
    let scoresFilter = {};    
    let nationalityFilter = {};
    let areaExpertiseFilter = {};
    let bodyUpFilter = {};    
    let bodyDownFilter = {};    
    let heightFilter = {};
    let ageFilter = {};
    let termFilter = {};
    let labelsFilter = {};
    let sortFilter = prepareSort(sortBy,orderBy);
    let listTypeFilter = {};
    let adminsFilter = {};
    let clientFilter = {};

    if (admins.length) adminsFilter = {admins:admins} ;
    if (languages.length) languagesFilter = {languages: languages};
    if (types.length) typesFilter = {types: types};
    if (users.length) usersFilter = {users: users};
    if (country.length) countryFilter = {country: country};
    if (city.length) cityFilter = {city: city};
    if (activity.length) activityFilter = {activity: activity};
    if (gender.length) genderFilter = {gender: gender};
    if (bmgStatus.length) bmgStatusFilter = {bmgStatus: bmgStatus};
    if (scores) scoresFilter = {scores: scores};
    if (nationality.length) nationalityFilter = {nationality: nationality};
    if (areaExpertise.length) areaExpertiseFilter = {areaExpertises: areaExpertise};
    if (bodyUp.length) bodyUpFilter = {bodyUp: bodyUp};
    if (bodyDown.length) bodyDownFilter = {bodyDown: bodyDown};
    if (clientId && clientId!=='') clientFilter = {_client:clientId}
    if (minHeight && maxHeight) heightFilter = {height: {min: !isNaN(Number(minHeight)) ? Number(minHeight) : 1 ,max: !isNaN(Number(maxHeight)) ? Number(maxHeight) : 1000  }};
    if (minAge && maxAge) ageFilter = {age: {min: !isNaN(Number(minAge)) ? Number(minAge) : 1 ,max: !isNaN(Number(maxAge)) ? Number(maxAge) : 1000  }};      
    if (searchTerm.length) termFilter = {term: searchTerm};
    if (listType) listTypeFilter = {listType:listType};
    if (labels) labelsFilter = {label:labels}; 
    if (geographicAvailability) geoAvailablitiesFilter = { geographicAvailability : geographicAvailability };

    const newFilters = {
      ...adminsFilter,
      ...languagesFilter,
      ...typesFilter,
      ...usersFilter,
      ...countryFilter,
      ...cityFilter,
      ...activityFilter,
      ...genderFilter,
      ...bmgStatusFilter,
      ...scoresFilter,
      ...nationalityFilter,
      ...areaExpertiseFilter,
      ...bodyUpFilter ,
      ...bodyDownFilter,
      ...heightFilter,
      ...ageFilter,  
      ...termFilter,
      ...sortFilter,
      ...listTypeFilter,
      ...labelsFilter,
      ...geoAvailablitiesFilter,
      ...clientFilter
    };
    
    dispatch(saveFilters({section:section,data:newFilters}));

    onCloseDrawer(); 

  };

  const onReset = () => {
    
    setLanguages([]);
    setAdmins([]);
    setTypes([]);
    setUsers([]);
    setCountry([]);
    setCity([]);
    setActivity([]);
    setGender([1,2]);      
    setBmgStatus([]);
    setScores([]);
    setLabels([]);
    setNationality([]);
    setAreaExpertise([]);
    setBodyUp([]);
    setBodyDown([]);
    setBodyDown([]);  
    setMinHeight(100);
    setMaxHeight(200);
    setMinAge(10);
    setMaxAge(100);    
    setSearchTerm('');
    setClientId('');
    setOrderBy(-1);
    setSortBy('cdate');
    setListType(defaultListType);

    dispatch(saveFilters({section:section,data:{listType:defaultListType}}));

  };


  const tagRender = (props) => {
        
    const { label, value, closable, onClose } = props;

    const item = storedLabelList.find((ele)=>ele._id === value);

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"white"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        className="tag" 
        style={{
            padding:"5px",
            backgroundColor: item && item.color ? item.color : 'var(--accent-color)',
            color:"white",
        }}
      >{label}</Tag>
    );
};

  const filtersContent = ()=>{

    const onChangeMinHeight = (e) =>{
      setMinHeight(e.target.value);
    };
    const onChangeMaxHeight = (e) =>{
      setMaxHeight(e.target.value);
    };
    const onChangeMinAge = (e) =>{
      setMinAge(e.target.value);
    };
    const onChangeMaxAge = (e) =>{
      setMaxAge(e.target.value);
    };   

    switch(section) {
      case 'dashboard':
        return (<></>);

      case 'agenda':
        return (<></>);

      case 'todo':
        return (
          <div className="filter-container">
          
            <div className="filter-row">
              <div>
                <h4 >{t('filter.labels')}</h4>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Filtrer par Labels"
                    defaultValue={[]}
                    tagRender={tagRender}
                    value={labels}
                    onChange={setLabels}
                    options={formatListWithValueLabel(storedLabelList)}
                    className="filter-select"
                  />            
              </div>
            </div>  
    
          </div>
        );

      case 'user':
        return (
          <div className="filter-container">
            <div className="filter-row  right">
              <Checkbox.Group
                options={Genders}
                defaultValue={gender}
                value={gender}
                onChange={setGender}
                className="custom-checkbox"
              />
            </div>
    
            <div className="filter-row column">
              <div>
                <h4 >{t('filter.bmgStatus')}</h4>
              </div>
              <div>
                <h4 >{t('filter.scores')}</h4>
              </div>
            </div>  
    
            <div className="filter-row column">
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par Statuts"
                  defaultValue={[]}
                  value={bmgStatus}
                  onChange={setBmgStatus}
                  options={BmgStatusArr}
                  className="filter-select"
                />
              </div>
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par Etoiles"
                  defaultValue={[]}
                  value={scores}
                  onChange={setScores}
                  options={ScoresValues}
                  className="filter-select"
                />
              </div>                  
            </div>    
    
            <div className="filter-row">
              <div>
                <h4 >{t('filter.labels')}</h4>
                  <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      optionLabelProp="label"
                      optionFilterProp="label"
                      placeholder="Filtrer par Labels"
                      defaultValue={[]}
                      tagRender={tagRender}
                      value={labels}
                      onChange={setLabels}
                      options={formatListWithValueLabel(storedLabelList)}
                      className="filter-select"
                  />            
              </div>
            </div>  

            <div className="filter-row column">
              <div>
                <h4 >{t('filter.languages')}</h4>
              </div>
              <div>
                <h4 >{t('filter.areaExpertises')}</h4>
              </div>
            </div>  
    
            <div className="filter-row column">
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par Langues"
                  defaultValue={[]}
                  value={languages}
                  onChange={setLanguages}
                  options={Languages}
                  className="filter-select"
                />
              </div>
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par Expertises"
                  defaultValue={[]}
                  value={areaExpertise}
                  onChange={setAreaExpertise}
                  options={AreaExpertises}
                  className="filter-select"
                />
              </div>                  
            </div>            

            <div className="filter-row column">
              <div>
                <h4 >{t('filter.geographicAvailibilities')}</h4>
              </div>
              <div>
                <h4 >{t('filter.city')}</h4>
              </div>
            </div>  

            <div className="filter-row column">
              <div>
                <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                      
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Filtrer par Disponibilité Géographique"
                    defaultValue={[]}
                    value={geographicAvailability}
                    onChange={setGeographicAvailabitity}
                    options={convertListToValueLabel(geographicAvailibilities)}
                    className="filter-select"
                  />   
              </div>
              <div>
                <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                      
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Filtrer par Ville"
                    defaultValue={[]}
                    value={city}
                    onChange={setCity}
                    options={BmgFilters && BmgFilters.client ? BmgFilters.client.city : []}
                    className="filter-select"
                  />   
              </div>                  
            </div>    
    
            <div className="filter-row column">
              <div>
                <h4 >{t('filter.nationality')}</h4>
              </div>
              <div>
                <h4 >{t('filter.country')}</h4>
              </div>
            </div>  
    
            <div className="filter-row column">
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par Nationalités"
                  defaultValue={[]}
                  value={nationality}
                  onChange={setNationality}
                  options={changeArrayToLabelValue(CountriesFr)}
                  className="filter-select"
                />
              </div>
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par Pays"
                  defaultValue={[]}
                  value={country}
                  onChange={setCountry}
                  options={changeArrayToLabelValue(CountriesFr)}
                  className="filter-select"
                />
              </div>                  
            </div>    
    
            <div className="filter-row column">
              <div>
                <h4 >{t('filter.height')}</h4>
              </div>
              <div>
                <h4 >{t('filter.age')}</h4>
              </div>
            </div>   
    
            <div className="filter-row column">
              <div>
                <Input 
                  defaultValue={minHeight}
                  id="minHeight"
                  name="minHeight"
                  placeholder="Min." 
                  className="small-input"
                  onChange={onChangeMinHeight}
                />
                <span className="gutter">à</span> 
                <Input 
                  defaultValue={maxHeight}
                  id="maxHeight"
                  name="maxHeight"
                  placeholder="Max." 
                  className="small-input"
                  onChange={onChangeMaxHeight}
                />
                <span className="gutter">cm</span>          
              </div>
              <div>
                <Input 
                    defaultValue={minAge}
                    id="minAge"
                    name="minAge"
                    placeholder="Min." 
                    className="small-input"
                    onChange={onChangeMinAge}
                  />
                  <span className="gutter">à</span> 
                  <Input 
                    defaultValue={maxAge}
                    id="maxAge"
                    name="maxAge"
                    placeholder="Max." 
                    className="small-input"
                    onChange={onChangeMaxAge}
                  />
                  <span className="gutter">ans</span>
                            
              </div>         
            </div>       
    
            <div className="filter-row column">
              <div>
                <h4 >{t('filter.bodyUp')}</h4>
              </div>
              <div>
                <h4 >{t('filter.bodyDown')}</h4>
              </div>
            </div>   
    
            <div className="filter-row column">
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    minWidth:'80px'
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par type de Haut"
                  defaultValue={[]}
                  value={bodyUp}
                  onChange={setBodyUp}
                  options={BodySizes}
                  className="filter-select"
                />
              </div>
                <div>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                      minWidth:'80px'
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Filtrer par type de Bas"
                    defaultValue={[]}
                    value={bodyDown}
                    onChange={setBodyDown}
                    options={BodySizes}
                    className="filter-select"
                  />
                </div>         
            </div>   
          </div>
        );
    

      case 'client':
        return (
          <div className="filter-container">
          
            <div className="filter-row">
              <div>
                <h4 >{t('filter.city')}</h4>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Filtrer par Ville"
                    defaultValue={[]}
                    value={city}
                    onChange={setCity}
                    options={BmgFilters && BmgFilters.client ? BmgFilters.client.city : []}
                    className="filter-select"
                  />            
              </div>              
            </div>  
    
            <div className="filter-row">
              <div>
                <h4 >{t('filter.activity')}</h4>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Filtrer par type d'Activités"
                    defaultValue={[]}
                    value={activity}
                    onChange={setActivity}
                    options={BmgFilters && BmgFilters.client ? BmgFilters.client.activity : []}
                    className="filter-select"
                  />            
              </div>
            </div>  
    
    
            <div className="filter-row">
              <div>
                <h4 >{t('filter.country')}</h4>
                <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                      
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Filtrer par Pays"
                    defaultValue={[]}
                    value={country}
                    onChange={setCountry}
                    options={changeArrayToLabelValue(CountriesFr)}
                    className="filter-select"
                  />            
              </div>
            </div>

            <div className="filter-row">
                <div>
                  <h4 >{t('filter.labels')}</h4>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                          width: '100%',
                        }}
                        optionLabelProp="label"
                        optionFilterProp="label"
                        placeholder="Filtrer par Labels"
                        defaultValue={[]}
                        tagRender={tagRender}
                        value={labels}
                        onChange={setLabels}
                        options={formatListWithValueLabel(storedLabelList)}
                        className="filter-select"
                    />            
                </div>
              </div>    
          </div>
        );

      case 'operation':
        return (
          <div className="filter-container">
          
            <div className="filter-row">
                <div>
                  <h4 >{t('filter.clients')}</h4>
                    <Select
                      style={{
                        width: '100%',                    
                      }}
                      optionLabelProp="label"
                      optionFilterProp="label"
                      placeholder="Filtrer par Client"
                      defaultValue={null}
                      value={clientId}
                      onChange={setClientId}
                      options={clientList}
                      className="filter-select filter-special"
                    />
                </div>
            </div> 

            <div className="filter-row column">
              <div>
                <h4 >{t('filter.languages')}</h4>
              </div>
              <div>
                <h4 >{t('filter.types')}</h4>
              </div>          
            </div>  
    
            <div className="filter-row column">
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par Langues"
                  defaultValue={[]}
                  value={languages}
                  onChange={setLanguages}
                  options={Languages}
                  className="filter-select"
                />
              </div>    
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par Types"
                  defaultValue={[]}
                  value={types}
                  onChange={setTypes}
                  options={BmgFilters && BmgFilters.operation ? BmgFilters.operation.type : []}
                  className="filter-select"
                />
              </div>                             
            </div>      



            <div className="filter-row column">
              <div>
                <h4 >{t('filter.labels')}</h4>
              </div>
              <div>
                <h4 >{t('filter.admins')}</h4>
              </div>          
            </div>  
    
            <div className="filter-row column">
              <div>
              <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      optionLabelProp="label"
                      optionFilterProp="label"
                      placeholder="Filtrer par Labels"
                      defaultValue={[]}
                      tagRender={tagRender}
                      value={labels}
                      onChange={setLabels}
                      options={formatListWithValueLabel(storedLabelList)}
                      className="filter-select"
                  />   
              </div>    
              <div>
                <Select
                  style={{
                    width: '100%',                    
                  }}
                  mode="multiple"
                  allowClear
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Filtrer par Admin"
                  defaultValue={[]}
                  value={admins}
                  onChange={setAdmins}
                  options={BmgFilters && BmgFilters.admin ? BmgFilters.admin : []}
                  className="filter-select"
                />
              </div>                             
            </div>      
              
          </div>
        );

        case 'joboffer':
          return (
            <div className="filter-container">
              <div className="filter-row">
                <div>
                  <h4 >{t('filter.labels')}</h4>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                          width: '100%',
                        }}
                        optionLabelProp="label"
                        optionFilterProp="label"
                        placeholder="Filtrer par Labels"
                        defaultValue={[]}
                        tagRender={tagRender}
                        value={labels}
                        onChange={setLabels}
                        options={formatListWithValueLabel(storedLabelList)}
                        className="filter-select"
                    />            
                </div>
              </div>    
            </div>
          );
  

      case 'message':
        return (
          <div className="filter-container">
          
            <div className="filter-row">
              <div>
                <h4 >{t('filter.users')}</h4>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Filtrer par Utilisateurs"
                    defaultValue={[]}
                    value={users}
                    onChange={setUsers}
                    options={formatUserListWithValueLabel(storedUserList)}
                    className="filter-select"
                  />            
              </div>
            </div>  
    
          </div>
        );

      case 'setting':
        return (<></>);        

      default:
        return (<></>);
    }
  };
  
  return (
      <div className="header">
        <div className='mobile-menu'>
          <MenuOutlined className='mobile-menu-selector' onClick={toogleMobileMenu}/>
          <div className='mobile-menu-items' style={{display:mobileMenuToogle?'':'none'}}>
            <MenuItems/>
          </div>
        </div>
        {hasBack && hasNavigateBack && 
          <LeftCircleOutlined onClick={()=>navigate(-1)} className="icon-header" />
        }
        <div className="header-block" style={{width:'300px'}}>
          <SectionIcon section={section} className="icon-header"/>
          <h2>{t(`${subSection ? subSection : section}.title`)}</h2>    
          {(actionContent !== undefined && actionContent !== null) &&
            actionContent
          }   
          {loading &&
              <div className="spinner">
                <Spin/>
              </div>
          }          
          {(todoContent !== undefined && todoContent !== null) &&
            todoContent
          }               
        </div>
        <div style={{width:'80%',marginRight: '16%',display: 'flex',alignContent: 'center',justifyContent: 'space-evenly'}}>  

          {hasSort && section === 'user' &&
            <>
              <div style={{marginRight:'16px'}}>
                <Select
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Sort"
                  defaultValue={sorterItem ? sorterItem.sortBy : sortBy}
                  value={sortBy}
                  onChange={onChangeSortBy}
                  options={sortByArr}
                  className="filter-select-sort header-sorter"
                />
              </div>     
              <div>
                <Select
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Ordre"
                  defaultValue={sorterItem ? sorterItem.orderBy : orderBy}
                  value={orderBy}
                  onChange={onChangeOrderBy}
                  options={orderByArr}
                  className="filter-select-sort header-sorter"
                />
              </div>
            </>
          }

          {hasSort && section === 'message' &&
            <>
              <div style={{marginRight:'16px'}}>
                <Select
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Type de liste"
                  defaultValue={listType}
                  value={listType}
                  onChange={onChangeListType}
                  options={messageListType}
                  className="filter-select-sort"
                />
              </div>     
            </>
          }          

          { hasSearch && 
            <Search          
              placeholder="Rechercher"
              className="header-search"
              disabled={loading !== undefined ? loading : false}
              allowClear
              defaultValue={searchTerm}      
              value={searchTerm} 
              onChange={onChangeSearchTerm}
              onSearch={onSearchTerm}
              style={{
                width: 200,
              }}
            />          
          }

          { hasFilters && 
              <Button style={{marginLeft:'100px'}}  className={`button-icon filters spacer ${storedFilters && Object.keys(storedFilters).length > 2 ? 'secondary-color' : ''}`} alt={t('filter.title')} title={t('filter.title')} onClick={showDrawer} type="primary" ><FilterOutlined/></Button> 
          }

        </div>

        { hasFilters && 
            <Drawer
              title={<div className="h3b grey-dark"><FilterOutlined/> {t('filter.title')}</div>}
              placement="right"
              width={500}
              onClose={onCloseDrawer}
              open={open}
              extra={
                <Space>
                  <Button className="button-small cancel"  onClick={onCloseDrawer}>{t('buttons.cancel')}</Button>
                  <Button className="button-small cancel"  onClick={onReset}>{t('buttons.reset')}</Button>
                  <Button className="button-small apply" type="primary" onClick={onFilterApply}>{t('buttons.apply')}</Button>
                </Space>
              }
            >{filtersContent()}</Drawer>        
        }

      </div>  
  );

};

export default HeaderBar;